import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import APIRequest from '../services/api-service';
import { modalStore, sendMessage } from '../state';

import ThreeDSIframe from '../components/card/components/threeds-modal';
import Feedback from '../components/feedback';
import { isInFrame } from '../utils/utilFunctions';
import { environments } from '../utils';
import '../style/main.scss';

interface RouteParams {
  transactionReference: string;
}

const api = new APIRequest();

function ThreeDSType() {
  const { transactionReference: reference } = useParams<RouteParams>();
  const { feedback, modalState } = modalStore();

  useLayoutEffect((): string | any => {
    // Handle redirect for test(sandbox) payments
    if (process.env.VITE_ENV_TYPE === environments.TEST) {
      if (isInFrame()) {
        return (document.location.href = `/charge-card/threeds-test/${reference}`);
      }
      return (window.location.href = `/charge-card/threeds-test/${reference}`);
    }

    sendMessage('VERIFY_THREEDS_TYPE', async () => {
      const response = await api.getThreeDSType({ reference });
      const { data } = response;
      // Handle redirects for live payments
      if (data?.type === 'url') {
        if (isInFrame()) {
          document.location.href = data?.content;
          return response;
        }
        window.location.href = data?.content;
      }
      if (data?.type === 'html') {
        if (isInFrame()) {
          document.location.href = `/charge-card/threeds-type/${reference}`;
          return response;
        }
        window.location.href = `/charge-card/threeds-type/${reference}`;
      }
      return response;
    });
  }, [reference]);

  return (
    <>
      {feedback && modalState === 'Something’s wrong' ? (
        <div className="kpy__root-container">
          <div className="secured__banner">
            <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
            <p>
              Secured by <strong>Korapay</strong>
            </p>
          </div>
          <div className="kpy-col__container">
            <Feedback />
          </div>
        </div>
      ) : (
        <div className="kpy__root-container">
          <ThreeDSIframe />
        </div>
      )}
    </>
  );
}

export default ThreeDSType;
