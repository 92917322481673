import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface ThreeDSModalType {
  children: ReactNode;
}
function ThreeDSModal({ children }: ThreeDSModalType) {
  const [domReady, setDomReady] = useState(false);
  const iframeWrapper = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (!iframeWrapper.current) {
      const iframeBody = document.createElement('main');
      iframeBody.classList.add('threeds-modal');
      iframeWrapper.current = iframeBody;
    }
    document.body.appendChild(iframeWrapper.current);
    setDomReady(true);

    return () => {
      if (iframeWrapper.current) {
        document.body?.removeChild(iframeWrapper.current);
      }
    };
  }, []);

  return domReady ? createPortal(<>{children}</>, iframeWrapper.current!) : null;
}

interface ThreeIframeType {
  type?: string;
  cancelThreeDS?: () => void;
  url?: string;
}

function ThreeDSIframe({ type = '', cancelThreeDS, url = '' }: ThreeIframeType) {
  const [showIframe, setShowIframe] = useState(false);
  const height = window.innerHeight;

  useEffect(() => {
    if (!showIframe && type) {
      setTimeout(() => setShowIframe(true), 2500);
    }
  }, [showIframe, type]);

  return (
    <ThreeDSModal>
      <div className={showIframe ? 'threeds-modal--loader' : 'threeds-modal--loader visible'}>
        <p>Please wait while we connect to your bank</p>
      </div>
      {type === 'url' && <iframe src={url} width="100%" height={height} frameBorder="0" title="ThreeDS Authentication Modal"></iframe>}
      {cancelThreeDS && (
        <button className="threeds-modal--close-btn" onClick={cancelThreeDS} aria-label="Cancel threeds authentication">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.92576 2.92637C6.82759 -0.974642 13.1731 -0.974642 17.0749 2.92637C20.975 6.82737 20.975 13.1745 17.0749 17.0755C15.124 19.0256 12.562 20.0002 9.9999 20.0002C7.43784 20.0002 4.87668 19.0255 2.92576 17.0755C-0.975245 13.1745 -0.975245 6.82737 2.92576 2.92637ZM5.87382 12.9486C5.54785 13.2745 5.54785 13.8015 5.87382 14.1274C6.0364 14.29 6.24982 14.3717 6.46328 14.3717C6.6767 14.3717 6.89016 14.29 7.05273 14.1274L9.99994 11.1794L12.9463 14.1266C13.1097 14.2891 13.3232 14.3709 13.5358 14.3709C13.7492 14.3709 13.9627 14.2891 14.1252 14.1266C14.4512 13.8006 14.4512 13.2729 14.1252 12.9477L11.1788 10.0005L14.126 7.0533C14.452 6.72733 14.452 6.19958 14.126 5.87443C13.8009 5.54845 13.2731 5.54845 12.9472 5.87443L9.99997 8.82163L7.05277 5.87443C6.7268 5.54845 6.19987 5.54845 5.8739 5.87443C5.54793 6.19958 5.54793 6.72733 5.8739 7.0533L8.8211 10.0005L5.87382 12.9486Z"
              fill="#000"
            />
          </svg>
        </button>
      )}
    </ThreeDSModal>
  );
}

export default ThreeDSIframe;
