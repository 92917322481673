import React, { useEffect, useState } from 'react';
import { modalStore, sendMessage } from '../../state';
import APIRequest from '../../services/api-service';

import MobileMoneyInit from './mobile-money-actions/mobile-money-init';
import CheckTransaction from './mobile-money-actions/check-transaction';
import MobileMoneyOTP from './mobile-money-actions/mobile-money-otp';
import PaymentQuery from './mobile-money-actions/payment-query';
import SandboxCheckTransaction from './mobile-money-actions/sandbox-check-transaction';
import Copyable from '../shared/copyable';
import { environments } from '../../utils';

const api = new APIRequest();

const defaultCountry = {
  KES: '254',
  GHS: '233',
  NGN: '234',
  XAF: '237',
  XOF: '225'
};

export default function MobileMoney() {
  const { mobileMoney, public_key, payment_reference, currency, update, sandboxInstructions, reference, sessionAttempt } = modalStore();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState(defaultCountry[currency] || '');
  const [authDetails, setAuthDetails] = useState({
    reference: payment_reference,
    otp: '',
    phone: ''
  });

  const chargePhoneNumber = () => {
    update({
      sessionAttempt: sessionAttempt + 1
    });
    setAuthDetails(prevState => ({
      ...prevState,
      phone: `*****${phoneNumber?.slice(-4)}`
    }));
    sendMessage('INIT_CHARGE_MOBILE_MONEY', () =>
      api.initMobileMoneyCharge({
        public_key,
        payment_reference,
        mobile_money: {
          number: `${country}${phoneNumber.slice(1)}`
        }
      })
    );
  };

  const resendSTK = () => {
    update({
      loader: true
    });
    sendMessage('RESEND_MOBILE_MONEY_STK', () =>
      api.resendSTKMobileMoneyCharge({
        public_key,
        reference
      })
    );
  };

  const processPayment = () => {
    sendMessage('AUTH_CHARGE_MOBILE_MONEY', () =>
      api.authOTPMobileMoneyCharge({
        public_key,
        reference,
        token: authDetails?.otp
      })
    );
  };

  const resetMobileMoneyStage = () => {
    setAuthDetails(prevAuthDetails => ({
      ...prevAuthDetails,
      otp: '',
      phone: ''
    }));
  };

  const retryOTPRequest = () => {
    sendMessage('RESEND_CHARGE_MOBILE_MONEY', () =>
      api.initMobileMoneyCharge({
        public_key,
        payment_reference,
        mobile_money: {
          number: `${country}${phoneNumber.slice(1)}`
        }
      })
    );
  };

  useEffect(() => {
    sandBoxContent();
  }, [mobileMoney.stage]);

  const renderContent = () => {
    switch (mobileMoney.stage) {
      case 'mobile_money':
        return (
          <MobileMoneyInit
            chargePhoneNumber={chargePhoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            country={country}
            setCountry={setCountry}
          />
        );
      case 'mobile_money_otp':
        return (
          <MobileMoneyOTP
            maskPhoneNumber={authDetails.phone}
            processPayment={processPayment}
            resetMobileMoneyStage={resetMobileMoneyStage}
            otp={authDetails.otp}
            setAuthDetails={setAuthDetails}
          />
        );
      case 'mobile_money_check':
        if (process.env.VITE_ENV_TYPE === environments.TEST && sandboxInstructions) {
          return <SandboxCheckTransaction retryRequest={resendSTK} />;
        }

        return <CheckTransaction retryRequest={resendSTK} country={country} />;
      case 'mobile_money_payment_query':
        return <PaymentQuery />;
      default:
        return (
          <div className="kpy-col__pay-form">
            <p className="details-error" tabIndex="-1">
              There was an error while processing your payment. Please try another payment method.
            </p>
          </div>
        );
    }
  };

  const sandBoxContent = () => {
    switch (mobileMoney.stage) {
      case 'mobile_money':
        update({
          sandboxInstructions: (
            <>
              <p className="accordion-text">Use the test details below to simulate a successful transaction. </p>
              <div className="accordion-highlight">
                <span>Phone Number</span>
                <Copyable
                  prefix={`+${country}`}
                  text={currency === 'KES' ? '700000000' : '240000000'}
                  textModifier={() => (currency === 'KES' ? '700 000 000' : '240 000 000')}
                  buttonClassName="accordion-copy"
                  spanClassName="copy-flex"
                  copyText="Copied ✓"
                />
              </div>
              <p className="accordion-text">Use the test details below to simulate a insufficient fund transaction. </p>
              <div className="accordion-highlight">
                <span>Phone Number</span>
                <Copyable
                  prefix={`+${country}`}
                  text={currency === 'KES' ? '734611986' : '240000000'}
                  textModifier={() => (currency === 'KES' ? '734 611 986' : '241 000 000')}
                  buttonClassName="accordion-copy"
                  spanClassName="copy-flex"
                  copyText="Copied ✓"
                />
              </div>
              <p className="accordion-text">Or any other 10 digit number to simulate an invalid phone number.</p>
            </>
          )
        });
        break;
      case 'mobile_money_otp':
        update({
          sandboxInstructions: (
            <>
              <p className="accordion-text">Use the test details below to simulate to authenticate your transaction</p>
              <div className="accordion-highlight">
                <span>One-time Pin (OTP)</span>
                <Copyable text="123456" buttonClassName="accordion-copy" copyText="Copied ✓" />
              </div>
              <p className="accordion-text">Or any other 6 digit number to simulate an invalid OTP.</p>
            </>
          )
        });
        break;
      case 'mobile_money_check':
        update({
          sandboxInstructions: (
            <>
              <p className="accordion-text">Use the test details below to simulate to authenticate your transaction</p>
              <div className="accordion-highlight">
                <span>Mobile Money PIN</span>
                <Copyable text="1234" buttonClassName="accordion-copy" copyText="Copied ✓" />
              </div>
              <p className="accordion-text">Or any other 4 digit number to simulate an invalid pin.</p>
            </>
          )
        });
        break;
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
}
