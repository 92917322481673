import React, { useLayoutEffect, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import APIRequest from '../services/api-service';
import { modalStore, sendMessage } from '../state';
import Feedback from '../components/feedback';
import ThreeDSIframe from '../components/card/components/threeds-modal';
import SessionEnd from '../components/session-end';
import loaderIcon from '../assets/kpy-loader.svg';
import '../style/main.scss';

interface RouteParams {
  transactionReference: string;
}

const api = new APIRequest();

function RedirectPayment() {
  const { transactionReference: reference } = useParams<RouteParams>();
  const { loader, update, openIframe, redirectUrl, modalState, feedback } = modalStore();
  const payWithBankQueryInterval = process.env.VITE_PAY_WITH_BANK_QUERY_INTERVAL || 120000;
  const confirmPayment = useRef<null | NodeJS.Timeout>(null);
  useLayoutEffect(() => {
    update({ page: 'Charge pay with bank Verification' });
  }, []);

  const getRedirectUrl = () => {
    sendMessage('REDIRECT_URL', () => api.getRedirectUrl(reference ?? ''));
  };

  const checkTransaction = () => {
    sendMessage('CONFIRM_PAYWITHBANK', () =>
      api.queryBankTransferCharge({
        reference,
        env: process.env.VITE_ENV_TYPE
      })
    );
  };

  useLayoutEffect(() => {
    getRedirectUrl();
  }, []);

  useEffect(() => {
    confirmPayment.current = setInterval(() => {
      checkTransaction();
    }, Number(payWithBankQueryInterval));

    return () => {
      clearInterval(confirmPayment.current);
    }
  }, []);

   if (modalState === 'session-end') {
     return <SessionEnd />;
   }

  return (
    <div className="kpy__root-container">
      <div className="secured__banner">
        <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
        <p>
          Secured by <strong>Korapay</strong>
        </p>
      </div>
      {loader && (
        <div className="kpy-col__container">
          <div className={loader ? 'kpy--modal--inner display-block' : 'kpy--modal--inner display-none'}>
            <div className="kpy--modal-content">
              <div className="kpy--modal--loader kpy--loader">
                <img src={loaderIcon} alt="Loading" />
                <p>loading...</p>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {feedback && <div className="kpy-col__container"> <Feedback /></div>}
      {openIframe && (
        <ThreeDSIframe type={'url'} url={redirectUrl} cancelThreeDS={() => update({ openIframe: false, modalState: 'session-end' })} />
      )}
    </div>
  );
}
export default RedirectPayment;
