import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { bugsnagWhitelistErrors } from './utils/constant';
import { datadogRum } from '@datadog/browser-rum';

import App from './pages/Default';
import CardVerifyPage from './pages/ChargeVerification';
import CardThreeDSTypePage from './pages/ThreeDSType';
import CardThreeDSTestPage from './pages/ThreeDSTest';
import PayWithBankChargeVerification from './pages/ChargePayWithBankVerification';
import RedirectPayment from './pages/RedirectPayment';

datadogRum.init({
  applicationId: process.env.VITE_APP_DARKHORSE_BASE ?? '',
  clientToken: process.env.VITE_APP_DARKHORSE_CLIENT ?? '',
  site: process.env.VITE_APP_DARKHORSE_URL ?? 'us5.datadoghq.com',
  service: 'modal-fe',
  env: process.env.VITE_APP_DARKHORSE_AREA,
  version: '1.0.0',
  sessionSampleRate: Number(process.env.VITE_APP_DARKHORSE_SESSION ?? 100),
  sessionReplaySampleRate: Number(process.env.VITE_APP_DARKHORSE_REPLAY ?? 100),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [(url: string) => url.startsWith(`${process.env.VITE_MODAL_SERVER}`)]
});

datadogRum.startSessionReplayRecording();

Bugsnag.start({
  apiKey: process.env.VITE_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
  logger: null,
  releaseStage: process.env.VITE_APP_ENV,
  enabledReleaseStages: ['production', 'staging', 'review'],
  redactedKeys: [/^authorization$/i],
  onError(e) {
    return !bugsnagWhitelistErrors.includes(e.errors[0].errorMessage);
  }
});

// eslint-disable-next-line react-refresh/only-export-components
const ErrorBoundary: any = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root') as unknown as Element);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/charge-card/3ds/:transactionReference" element={<CardThreeDSTypePage />} />
          <Route path="/charge-card/3ds-test/:transactionReference" element={<CardThreeDSTestPage />} />
          <Route path="/charge-card/verify/:transactionReference" element={<CardVerifyPage />} />
          <Route path="/charge-pwb/verify/:transactionReference" element={<PayWithBankChargeVerification />} />
          <Route path="/redirect/payment/:transactionReference" element={<RedirectPayment />} />
          <Route path="*" element={<App />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </ErrorBoundary>
);
