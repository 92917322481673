import { ReactNode } from 'react';
import paybank from '../assets/pay-bank.svg';
import paycard from '../assets/pay-card.svg';
import paybanktransfer from '../assets/pay-bank-transfer.svg';
import paymobilemoney from '../assets/pay-mobile-money.svg';
import { verve, visa, mastercard } from './formats';

// Bank Images
import AlatByWema from "../assets/alat.svg";
import KudaBank from "../assets/kudabank.svg";
import ZenithBank from "../assets/zenith.svg";
import Access from '../assets/accessLogo.svg';
import Heritage from '../assets/heritageLogo.svg';
import Jaiz from '../assets/jaizLogo.svg';
import FCMB from '../assets/fcmblogo.svg';
import Fidelity from '../assets/fidelityLogo.svg';
import Opay from '../assets/opayLogo.svg';
import Union from '../assets/unionLogo.svg';
import Globus from '../assets/globusLogo.svg';
import GTBank from '../assets/gtbank.svg';
import FirstBankLogo from '../assets/fbLogo.svg'
import UBA from '../assets/ubalogo.svg';
import ABSA from '../assets/ABSAbanklogo.svg'
import AfricanBank from '../assets/africanbanklogo.svg'
import BidvestBank from '../assets/bidvestbanklogo.svg'
import CapitecBank from '../assets/capitecbanklogo.svg'
import FirstNationalBank from '../assets/firstnationalbanklogo.svg'
import InvestecBank from '../assets/investecbanklogo.svg'
import NedBank from '../assets/nedbanklogo.svg'
import StandardBank from '../assets/standardbanklogo.svg'
import TymeBank from '../assets/tymebanklogo.svg'
import CashVoucher from '../assets/cashVoucherLogo.svg'
import PagaBank from '../assets/pagalogo.svg';

import Wema from '../assets/wema.svg';
import Providus from '../assets/providus.svg';
import Sterling from '../assets/sterling.svg';
import Moniepoint from '../assets/moniepoint.svg';
import VFDlogo from '../assets/vfdLogo.svg'

export type switchPaymentChannelImageType = {
  pay_with_bank: [string, string];
  card: [string, string];
  bank_transfer: [string, string];
  mobile_money: [string, string];
};

export const switchPaymentChannelImage = (currency = 'NGN'): switchPaymentChannelImageType => {
  return {
    pay_with_bank: [paybank, `Pay with Bank ${currency === 'ZAR' ? '(Instant EFT)' : ''}`],
    mobile_money: [paymobilemoney, 'Pay with Mobile Money'],
    card: [paycard, 'Pay with Debit Card'],
    bank_transfer: [paybanktransfer, 'Pay with Bank Transfer']
  };
};

export type SwitchBanksTypes = {
  "kuda-mfb": { image: ReactNode; accent: string; color: string };
  zenith: { image: ReactNode; accent: string; color: string };
  wema: { image: ReactNode; accent: string; color: string };
  gtbank: { image: ReactNode; accent: string; color: string };
}

export const switchBanks = {
  'kuda-mfb': { image: KudaBank, accent: '#eff1ff', color: '#40196d' },
  zenith: { image: ZenithBank, accent: '#fff1f1', color: '#ff0000' },
  wema: { image: AlatByWema, accent: '#fff1f6', color: '#9a0d3e' },
  firstbank: { image: FirstBankLogo, accent: '#CDE3FE', color: '#022E64' },
  access: { image: Access, accent: '#FCE6CF', color: '#F28A1A' },
  fcmb: { image: FCMB, accent: '#5C268410', color: '#5C2684' },
  gtb: { image: GTBank, accent: '#c9947526', color: '#d94f00' },
  'globus-bank': { image: Globus, accent: '#FFCCD6', color: '#FF0033' },
  heritage: { image: Heritage, accent: '#E0F0DB', color: '#63B44B' },
  jaiz: { image: Jaiz, accent: '#D4F7E2', color: '#0E4C27' },
  sterling: { image: Sterling, accent: '#AA000010', color: '#AA0000' },
  fidelity: { image: Fidelity, accent: '#CDE3FE', color: '#002082' },
  providus: { image: Providus, accent: '#FDB81310', color: '#FDB813' },
  opay: { image: Opay, accent: '#1DCF9F10', color: '#1DCF9F' },
  unionbank: { image: Union, accent: '#009FDF10', color: '#009FDF' },
  uba: { image: UBA, accent: '#FEECEB', color: '#D81A0E' },
  absa: { image: ABSA, accent: '#FCCFD8', color: '#A90C2B' },
  'african-bank': { image: AfricanBank, accent: '#E7F1DA', color: '#89BC47' },
  'bidvest-bank-gro': { image: BidvestBank, accent: '#E3DFFC', color: '#160959' },
  capitec: { image: CapitecBank, accent: '#CCECFF', color: '#00466E' },
  'cash-voucher': { image: CashVoucher },
  fnb: { image: FirstNationalBank, accent: '#FEE9CD', color: '#FA9D1E' },
  investec: { image: InvestecBank, accent: '#D7E3F4', color: '#6795D3' },
  nedbank: { image: NedBank, accent: '#CCFFEC', color: '#00643E' },
  'standard-bank': { image: StandardBank, accent: '#CCD7FF', color: '#345FFE' },
  tymebank: { image: TymeBank, accent: '#FFF1CC', color: '#FFBE0A' },
  paga: { image: PagaBank, accent: '#F9C13B33', color: '#F27244' }
};

export const switchBankInfo = {
  monnify: { image: Moniepoint, name: 'Moniepoint MFB' },
  providus: { image: Providus, name: 'Providus Bank' },
  sterling: { image: Sterling, name: 'Sterling Bank' },
  wema: { image: Wema, name: 'Wema Bank' },
  vfd: { image: VFDlogo, name: 'VFD Microfinance Bank'},
  fidelity: { image: Fidelity, name: 'Fidelity Bank' },
};

export const switchProcessor = {
  mastercard: { image: mastercard },
  verve: { image: verve },
  visa: {image: visa}
};

export const switchCountryCode = (currency: string): string => {
  switch(currency){
    case 'NGN':
      return 'NG';
    case 'GHS':
      return 'GH';
    case 'ZAR':
      return 'ZA';
    case 'KES':
      return 'KE'
    default:
      return 'NG'
  }
} 

export const switchCountryCurrency = (currency: string): string => {
  switch(currency){
    case 'NGN':
      return 'N';
    case 'USD':
      return '$';
    default:
      return currency;
  }
}
